import router from '@/router';
import { createGtm } from '@gtm-support/vue-gtm';

export function useConditionallyGTM(app) {
    const gtmId = import.meta.env.VITE_GTM_ID;
    if (gtmId && gtmId.trim() !== '') { 
      app.use(createGtm({
        id: gtmId,
        enabled: true,
        loadScript: true,
        vueRouter: router,
      }));
    }
  }