import { get as getCookie } from 'tiny-cookie';
import request, { requestMethodType } from '@/plugins/request';
import store, { StoreState } from '@/store/index';
import router from '@/router';
import * as api from '@/plugins/api';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import type { ContractenX, ContractSummary, ContractX, OffertesX, Relatie } from '@/types/api';
import type { FlowName } from './flowInterface';

export type Country = {
  code: string; // 'NL'
  name: string; // 'Nederland'
};

type UserData = {
  email: string;
  mobile: string;
  role: 'user' | 'admin';
};

export interface AdminTable {
  id: number
  achternaam: string
  initialen: string
  geboortedatum: string
  woonplats: string
  email: string
  gebruikerEmail: string
}

type AdminData = {
  isImpersonating: boolean;
  tableData: AdminTable[];
}

type PendingIdealPayment = {
  contractId: number;
  paymentId: string;
  requestId: string;
};

export const mainInitialState: {
  admin: AdminData;
  contract: ContractX;
  contractFlow: any;
  contracts: ContractenX[];
  contractSummaries: ContractSummary[];
  countries: Country[];
  isLoaded: boolean;
  isLoading: boolean;
  isOpenDomain: FlowName | null;
  isLogout: boolean;
  loadingCount: number;
  loadingText: string;
  mobile: string;
  notifications: any[];
  offers: OffertesX[];
  pendingIdealPayment: PendingIdealPayment;
  personalInfo: Relatie;
  relations: Relatie[];
  showGlobalError: boolean;
  twoFaToken: string;
  unpaidContractsCount: number;
  user: UserData;
  username: string;
} = {
  admin: {
    isImpersonating: false,
    tableData: [],
  },
  contract: null,
  contractFlow: null, // Do we even need this?
  contracts: null,
  contractSummaries: null,
  countries: null,
  isLoaded: false,
  isLoading: false,
  isOpenDomain: null,
  isLogout: false,
  loadingCount: 0,
  loadingText: '',
  mobile: null,
  notifications: [],
  offers: null,
  pendingIdealPayment: null,
  personalInfo: null,
  relations: null,
  showGlobalError: true,
  twoFaToken: null,
  unpaidContractsCount: 0,
  user: null,
  username: null,
};

export const mainInterfaceGetters: GetterTree<StoreState, StoreState> = {
  aanvragen: (state) => state.aanvragen,
  admin: (state) => state.admin,
  contractFlow: (state) => state.contractFlow,
  contract: (state) => state.contract,
  contracts: (state) => state.contracts,
  contractSummaries: (state) => state.contractSummaries,
  countries: (state) => state.countries,
  currentUser: (state) => state.user,
  isAuthenticated: (state) => Boolean(state.user),
  isLoaded: (state) => state.isLoaded,
  isLoading: (state) => state.isLoading,
  loadingText: (state) => state.loadingText,
  isLogout: (state) => state.isLogout,
  isOpenDomain: (state) => state.isOpenDomain,
  notifications: (state) => state.notifications,
  offers: (state) => state.offers,
  pendingIdealPayment: (state) => state.pendingIdealPayment,
  personalInfo: (state) => state.personalInfo,
  relaties: (state) => state.relations,
  relations: (state) => state.relations,
  showGlobalError: (state) => state.showGlobalError,
  twoFaToken: (state) => state.twoFaToken,
  userData: (state): UserData => state.user,
  unpaidContractsCount: (state) => state.contractSummaries?.filter(summary => !summary.IsPaymentCompleted).length,
  hasContractsValue: (state) => state.contractSummaries?.filter(contract => contract.PrimoWaardeHuidigeMaandNaProlongatie > 0).length,
  username: (state) => state.username,
  mobile: (state) => state.mobile,
};

export const mainInterfaceMutations: MutationTree<StoreState> = {
  resetMainInterface(state) {
    Object.assign(state, structuredClone(mainInitialState));
  },
  setAuth(state, payload) {
    state.user = payload.user;
  },
  clearAuth(state) {
    state.user = null;
  },
  setAuthUsername(state, payload) {
    state.username = payload;
  },
  setMobile(state, payload) {
    state.mobile = payload;
  },
  setContract(state, payload: ContractX) {
    state.contract = payload;
  },
  setContracts(state, payload: ContractenX[]) {
    state.contracts = payload;
  },
  setContractSummaries(state, payload: ContractSummary[]): void {
    state.contractSummaries = payload;
  },
  setUnpaidContractsCount(state, payload: number): void {
    state.unpaidContractsCount = payload;
  },
  setCountries(state, payload) {
    state.countries = payload;
  },
  setLoaded(state, payload: boolean) {
    state.isLoaded = payload;
  },
  setOffers(state, payload) {
    state.offers = payload;
  },
  setPendingIdealPayment(state, payload) {
    state.pendingIdealPayment = payload;
  },
  setPersonalInfo(state, payload) {
    state.personalInfo = payload;
  },
  setRelaties(state, payload: Relatie[]) {
    state.relations = payload;
  },
  setRelations(state, payload) {
    state.relations = payload;
  },
  setTwoFaToken(state, payload) {
    state.twoFaToken = payload;
  },
  setIsLogout(state, payload: boolean) {
    state.isLogout = payload;
  },
  setIsOpenDomain(state, payload: FlowName | null) {
    state.isOpenDomain = payload;
  },
  setImpersonating(state, isImpersonating: boolean) {
    state.admin.isImpersonating = isImpersonating;
  },
  setAdminData(state, tableData: AdminTable[]) {
    state.admin.tableData = tableData;
  },
  addNotification(state, payload) {
    if (!payload.id) {
      payload.id = Math.random() * 100000;
    }

    setTimeout(() => {
      const notificationIndex = state.notifications.findIndex((item: any) => item.id == payload.id);
      state.notifications = [
        ...state.notifications.slice(0, notificationIndex),
        ...state.notifications.slice(notificationIndex + 1),
      ];
    }, 3000);

    state.notifications = [
      ...state.notifications,
      payload,
    ];
  },
  closeNotification(state, payload) {
    const notificationIndex = state.notifications.findIndex((item: any) => item.id == payload.id);
    state.notifications = [
      ...state.notifications.slice(0, notificationIndex),
      ...state.notifications.slice(notificationIndex + 1),
    ];
  },
  showGlobalError(state, payload) {
    state.showGlobalError = payload;
  },
  showLoading(state, payload: boolean) {
    state.loadingText = '';
    state.loadingCount += (payload ? 1 : -1);

    if (state.loadingCount < 0) {
      state.loadingCount = 0;
    }

    state.isLoading = state.loadingCount > 0;
  },
  showLoadingText(state, { isLoading, text = '' }) {
    state.loadingCount += (isLoading ? 1 : -1);

    if (state.loadingCount < 0) {
      state.loadingCount = 0;
    }

    state.isLoading = state.loadingCount > 0;

    if (!isLoading) {
      state.loadingText = '';
    } else if (text !== undefined) {
      state.loadingText = text;
    }
  },
  updateContractFlow(state, payload) {
    state.contractFlow = {
      ...state.contractFlow,
      ...payload,
    };
  },
  updateUsername(state, payload) {
    state.username = payload;
  },
};

export const mainInterfaceActions: ActionTree<StoreState, StoreState> = {
  async checkAuth(context) {
    const token = getCookie('token');

    if (!token) {
      await router.push({ path: '/login' });
      context.commit('setAuth', { user: null });
      context.commit('setLoaded', true);
    } else {
      try {
        const response = await request('/api/users/me', requestMethodType.GET, null, { headers: {}, auth: true });
        const { data } = response.data;
        context.commit('setAuth', { user: data });
      } catch (e: any) {
        if (e.code === 'ERR_NETWORK') {
          await router.push({ path: '/login' });
          context.commit('setAuth', { user: null });
        }

        await router.push({ path: '/login' });
        context.commit('setAuth', { user: null });
      }

      context.commit('setLoaded', true);
    }
  },
  async getContract(context, contractId): Promise<ContractX> {
    const { contract } = context.state;

    if (contract && contractId) {
      return contractId === contract.ContractID ? contract : await fetchContract(contractId);
    }
    if (contract && !contractId) {
      return contract;
    }
    if (!contract && contractId) {
      return await fetchContract(contractId);
    }
    if (!contract && !contractId) {
      return null;
    }

    async function fetchContract(id: number) {
      context.commit('showLoading', true);
      const response = await api.getContract(id);
      context.commit('showLoading', false);

      const data = response.Contract[0];
      context.commit('setContract', data);
      return data;
    }
  },
  async getContracts(context): Promise<ContractenX[]> {
    const { contracts } = context.state;

    if (contracts) {
      return contracts;
    }

    context.commit('showLoading', true);
    const response = await api.getContracts();
    context.commit('showLoading', false);

    const data = response.Contract;
    context.commit('setContracts', data);
    return data;
  },
  async getContractSummaries(context): Promise<ContractSummary[]> {
    const summaries = context.state.contractSummaries;

    if (summaries) {
      return summaries;
    }

    context.commit('showLoading', true);
    const response = await api.getContractSummaries();
    context.commit('showLoading', false);

    const data = response.Contract;
    context.commit('setContractSummaries', data);

    const unpaidCount = data.filter((contract: ContractSummary) => !contract.IsPaymentCompleted).length;
    store.commit('setUnpaidContractsCount', unpaidCount);

    return data;
  },
  async getCountries(context): Promise<Country[]> {
    const { countries } = store.state;

    if (countries) {
      return countries;
    }

    context.commit('showLoading', true);
    const response = await request('/api/countries', requestMethodType.GET, null, { headers: {}, auth: true });
    context.commit('showLoading', false);

    const data = response.data.sort((a: Country, b: Country) => (a.name > b.name ? 1 : -1));
    context.commit('setCountries', data);
    return data;
  },
  async getOffers(context): Promise<OffertesX[]> {
    const { offers } = context.state;

    if (offers) {
      return offers;
    }

    context.commit('showLoading', true);
    const response = await api.getOffers();
    context.commit('showLoading', false);

    const data = response.Offerte;
    context.commit('setOffers', data);
    return data;
  },
  async getPersonalInfo(context): Promise<Relatie> {
    context.commit('showLoading', true);
    const response = await api.getRelation();
    context.commit('showLoading', false);

    const data = response;
    context.commit('setPersonalInfo', data);
    return data;
  },
  async getRelations(context): Promise<Relatie[]> {
    context.commit('showLoading', true);
    const response = await api.getRelations();
    context.commit('showLoading', false);

    const data = response;
    context.commit('setRelations', data);
    return data;
  },
};

export const resetMainInterface = (): void => store.commit('resetMainInterface');

export const getContract = async (id?: number): Promise<ContractX> => await store.dispatch('getContract', id);
export const getContracts = async (): Promise<ContractenX[]> => await store.dispatch('getContracts');
export const getContractSummaries = async (): Promise<ContractSummary[]> => await store.dispatch('getContractSummaries');
export const getCountries = async (): Promise<Country[]> => await store.dispatch('getCountries');
export const getOffers = async (): Promise<OffertesX[]> => await store.dispatch('getOffers');
export const getPersonalInfo = async (): Promise<Relatie> => await store.dispatch('getPersonalInfo');
export const getCurrentUser = (): UserData => store.getters.currentUser;
export const getRelaties = async (): Promise<Relatie[]> => await store.dispatch('getRelations');

export const getIsLogout = (): boolean => store.getters.isLogout;
export const setIsLogout = (payload: boolean): void => store.commit('setIsLogout', payload);

export const getIsOpenDomain = (): FlowName | null => store.getters.isOpenDomain;
export const setIsOpenDomain = (payload: FlowName | null): void => store.commit('setIsOpenDomain', payload);

export const getAdminData = (): AdminData => store.getters.admin;
export const setAdminData = (payload: AdminTable[]): void => store.commit('setAdminData', payload);

export const setIsImpersonating = (payload: boolean): void => store.commit('setImpersonating', payload);

export const setClearAuth = (): void => store.commit('clearAuth');

export const setShowLoadingText = (isLoading: boolean, text?: string): void => store.commit('showLoadingText', { isLoading, text });
export const getLoadingText = (): string => store.getters.loadingText;

export const getCachedPersonalInfo = (): Relatie => store.getters.personalInfo;

export async function refreshContracts(options = { showSpinner: true }) {
  if (options.showSpinner) {
    showLoading(true);
  }

  const response = await api.getContracts();

  if (options.showSpinner) {
    showLoading(false);
  }

  const data = response.Contract;
  store.commit('setContracts', data);
}

export async function refreshContractSummaries(options = { showSpinner: true }) {
  if (options.showSpinner) {
    showLoading(true);
  }

  const response = await api.getContractSummaries();

  if (options.showSpinner) {
    showLoading(false);
  }

  const data = response.Contract;
  store.commit('setContractSummaries', data);
}

export async function refreshOffers(options = { showSpinner: true }) {
  if (options.showSpinner) {
    showLoading(true);
  }

  const response = await api.getOffers();

  if (options.showSpinner) {
    showLoading(false);
  }

  const data = response.Offerte;
  store.commit('setOffers', data);
}

export async function refreshPersonalInfo(options = { showSpinner: true }): Promise<Relatie> {
  if (options.showSpinner) {
    showLoading(true);
  }

  const response = await api.getRelation();

  if (options.showSpinner) {
    showLoading(false);
  }

  const data = response;
  store.commit('setPersonalInfo', data);
  return data;
}

export async function refreshRelaties(options = { showSpinner: true }) {
  if (options.showSpinner) {
    showLoading(true);
  }

  const response = await api.getRelations();

  if (options.showSpinner) {
    showLoading(false);
  }

  const data = response;
  store.commit('setRelaties', data);
}

export const setContract = (payload: ContractX): void => store.commit('setContract', payload);
export const setPersonalInfo = (payload: Relatie): void => store.commit('setPersonalInfo', payload);
export const getCachedContract = (): ContractX => store.getters.contract;

export const getUserData = (): UserData => store.getters.userData;
export const getTwoFaToken = (): string => store.getters.twoFaToken;
export const setTwoFaToken = (payload: string): void => store.commit('setTwoFaToken', payload);

export const isAuthenticated = (): boolean => store.getters.isAuthenticated;
export const getLoaded = (): boolean => store.getters.isLoaded;
export const getLoading = (): boolean => store.getters.isLoading;

export const getUnpaidContractsCount = (): number => store.getters.unpaidContractsCount;
export const getContractsValue = (): number => store.getters.hasContractsValue;

export const getMobile = (): string => store.getters.mobile;

export const getPendingIdealPayment = (): PendingIdealPayment => store.getters.pendingIdealPayment;
export const setPendingIdealPayment = (payload: PendingIdealPayment): void => store.commit('setPendingIdealPayment', payload);

// Temporary proxies
export const showGlobalError = (payload: boolean): void => store.commit('showGlobalError', payload);
export const getGlobalError = (): boolean => store.getters.showGlobalError;
export const showLoading = (payload: boolean): void => store.commit('showLoading', payload);
export const addNotification = (payload: { title: string; message: string; }) => store.commit('addNotification', payload);
export const updateContractFlow = (payload: any) => store.commit('updateContractFlow', payload);
export const closeNotification = (payload: any) => store.commit('closeNotification', payload);
